import api from "../../axios";

export const dataPaymentCollection = (params) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/invoice/payment/data-table", {
        params: {
          search: params.search,
          length: params.length,
          page: params.page,
          order: params.order,
          sort: params.sort,
        },
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const collectCashLog = (params) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/invoice/cash-log/data-table", {
        params: {
          search: params.search,
          length: params.length,
          page: params.page,
          order: params.order,
          sort: params.sort,
          status: params.status ? params.status : 0,
        },
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const collectCashLogLine = (params) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/v1/invoice/cash-log/data-table/" + params.ID, {
        params: {
          search: params.search,
          length: params.length,
          page: params.page,
          order: params.order,
          sort: params.sort,
        },
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const changeStatusCashLog = (params) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/api/v1/invoice/cash-log/change-status/${params.ID}/${params.status}`
      )
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const invoiceARClearing = (params) => {
  console.log("params : ", params);
  return new Promise((resolve, reject) => {
    api
      .get(`/api/v1/invoice/ar-clearing`, {
        params: {
          search: params.search,
          length: params.length,
          page: params.page,
          order: params.order,
          sort: params.sort,
          stats: params.stats,
          customer_code: params.customer_code,
        },
      })
      .then((r) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const bulkReadInvoice = (ids, codes, startCode = "", endCode = "") => {
  return api.post("/api/v1/invoice/bulk-read", {
    invoice_ids: ids && ids.length > 0 ? ids : [],
    codes: codes && codes.length > 0 ? codes : [],
    start_code: startCode ? startCode : null,
    end_code: endCode ? endCode : null,
  });
};

export const bulkUpdateInvoice = (ids, codes) => {
  return api.patch("/api/v1/invoice/bulk-print-count", {
    invoice_ids: ids && ids.length > 0 ? ids : [],
    codes: codes && codes.length > 0 ? codes : [],
  });
};
