<template>
  <div class>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Invoice Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="invoice.Code" disabled />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="invoiceData.client.name" disabled />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%">
      <template v-for="sales_order in invoiceData.sales_order">
        <vs-divider
          v-bind:key="sales_order.ID"
          style="width: 100%; margin-left: 2%"
          >Sales Order Code {{ sales_order.Code }}</vs-divider
        >
        <vs-table
          v-bind:key="sales_order.ID"
          stripe
          bordered
          :data="sales_order.Line"
          style="width: 100%; margin-left: 2%"
        >
          <template slot="thead">
            <vs-th>Code</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Quantity</vs-th>
            <vs-th>Information</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="index" v-for="(dt, index) in data">
              <vs-td>{{ dt.SkuCode }}</vs-td>
              <vs-td>{{ dt.ItemName }} ({{ dt.Unit }})</vs-td>
              <vs-td>{{ dt.Quantity }}</vs-td>
              <vs-td>
                Price : {{ priceFormat(dt.Price) }}
                <br />
                Discount : {{ priceFormat(dt.Discount) }}
                <br />
                Subtotal : {{ priceFormat(dt.Subtotal) }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%">Total Invoice</vs-divider>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>SubTotal</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          :value="priceFormat(invoiceData.subtotal)"
          disabled
        />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Discount</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          :value="priceFormat(invoiceData.discount)"
          disabled
        />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Subtotal Charge </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          :value="priceFormat(invoiceData.subtotal_charge)"
          disabled
        />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Other Cost</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          :value="priceFormat(invoiceData.other_cost)"
          disabled
        />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Tax ({{ invoiceData.tax_rate }}%)</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          :value="priceFormat(invoiceData.tax_amount)"
          disabled
        />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Total</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          :value="priceFormat(invoiceData.total)"
          disabled
        />
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%">List Payment</vs-divider>
    <vs-table
      stripe
      bordered
      :data="invoiceData.payment"
      style="width: 100%; margin-left: 2%"
    >
      <template slot="thead">
        <vs-th>No</vs-th>
        <vs-th>Date Payment</vs-th>
        <vs-th>Method</vs-th>
        <vs-th>Code</vs-th>
        <vs-th>Reference Code</vs-th>
        <vs-th>Amount</vs-th>
        <vs-th>Note</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="index" v-for="(dt, index) in data">
          <vs-td>{{ ++index }}</vs-td>
          <vs-td>{{ dateFormat(dt.Date) }}</vs-td>
          <vs-td>{{ dt.PaymentMethod }}</vs-td>
          <vs-td>{{ dt.PaymentCode }}</vs-td>
          <vs-td>{{ dt.ReferenceCode }}</vs-td>
          <vs-td>{{ priceFormat(dt.PaymentAmount) }}</vs-td>
          <vs-td>{{ dt.Notes }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%"></div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Paid</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          :value="priceFormat(invoiceData.paid)"
          disabled
        />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Unpaid</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          style="align-text: right"
          :value="priceFormat(invoiceData.unpaid)"
          disabled
        />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <!-- <vs-button
          v-if="invoiceData.unpaid<0"
          color="blue"
          type="line"
          icon-pack="feather"
          @click="closeInvoice()"
          icon="icon-save"
        >Close</vs-button>
        <vs-button
          v-if="invoiceData.unpaid>0"
          color="red"
          type="line"
          icon-pack="feather"
          @click="forceClose()"
          icon="icon-save"
        >Adjust Force Close</vs-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";
export default {
  components: {
    "v-select": vSelect,
  },
  props: {
    invoice: Object,
  },
  watch: {
    "invoice.ID"() {
      this.getDataInvoice();
    },
  },
  mounted() {
    this.getDataInvoice();
  },
  data() {
    return {
      invoiceData: {
        id: 0,
        code: "",
        faktur_number: "",
        company: {
          code: "",
          name: "",
        },
        delivery_order: {},
        client: {
          id: "",
          code: "",
          name: "",
        },
        bill_to: {},
        notes: "",
        date: "",
        due_date: "",
        time: "",
        subtotal: 0,
        tax_rate: 0,
        tax_amount: 0,
        discount: 0,
        other_cost: 0,
        total: 0,
        status: 0,
        sales_order: [],
        payment: [],
        delete_sales_order: [],
      },
      payment_method: 1,
      bank: 1,
      bank_account: {},
      invoice_payment_cod: [],
      payment_amount: 0,
    };
  },
  methods: {
    getDataInvoice() {
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/read", {
          params: {
            invoice_id: this.invoice.ID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            // const asyncFunction = async () => {
            this.invoiceValue(resp.data);
          } else {
            // //console.log(resp.code)
            this.$vs.dialog({
              type: "alert",
              color: "danger",
              title: `Alert`,
              text: "Whoops",
            });
          }
          this.$nextTick(() => {
            this.$vs.loading.close();
          });
        });
    },
    postInvoice() {
      let params = {
        invoice_id: this.invoiceData.ID,
        invoice_code: this.invoiceData.code,
        type_payment: this.payment_method.ID,
        payment_id: this.payment_method.ID == 5 ? this.payment_cod.ID : 0,
        amount: parseFloat(
          this.payment_amount.replace(/[^,\d]/g, "").toString()
        ),
        bank_account: this.payment_method.ID == 3 ? this.bank_account.ID : 0,
      };
      this.$vs.loading();
      this.$http
        .post("api/v1/invoice/store/payment", JSON.stringify(params))
        .then((resp) => {
          if (resp.code == 200) {
            console.log(resp);
            this.$vs.notify({
              title: "Success",
              text: "Payment Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getDataInvoice();
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$nextTick(() => {
            this.$vs.loading.close();
          });
        });
    },
    closeInvoice() {
      this.$http
        .put("api/v1/invoice/status/update", {
          invoice_ids: [this.invoice.ID],
          status: parseInt(4),
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            // this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$nextTick(() => {
            // this.$emit("closeDetail");
            this.$emit("closeDetail", true);
            this.$vs.loading.close();
          });
        });
    },
    forceClose() {
      this.$http
        .put("api/v1/invoice/status/update", {
          invoice_ids: [this.invoice.ID],
          status: parseInt(5),
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        });
    },
    dateFormat(value) {
      return moment.utc(String(value)).format("DD/MM/YYYY");
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
    invoiceValue(invoice) {
      this.invoiceData.items = [];
      this.invoiceData.delete_items = [];
      this.invoiceData.ID = invoice.invoice.ID;
      this.invoiceData.code = invoice.invoice.Code;
      this.invoiceData.delivery_order = invoice.deliveryOrder;
      (this.invoiceData.faktur_number = invoice.invoice.FakturNumber),
        (this.invoiceData.client = {
          id: invoice.customer.ID,
          address: invoice.customer.address,
          phone: invoice.customer.phone,
          code: invoice.invoice.ClientCode,
          name: invoice.invoice.ClientName,
        });
      this.invoiceData.client_code = invoice.invoice.ClientCode;
      this.invoiceData.client_name = invoice.invoice.ClientName;
      this.invoiceData.company_code = invoice.invoice.CompanyCode;
      this.invoiceData.company_name = invoice.invoice.CompanyName;
      this.invoiceData.notes = invoice.invoice.Note;
      this.invoiceData.date = this.dateFormat(invoice.invoice.Date.toString());
      this.invoiceData.due_date = this.dateFormat(
        invoice.invoice.DueDate.toString()
      );
      //   console.log(invoice.salesOrder[0].DueDate);
      this.invoiceData.time = invoice.invoice.Time;
      this.invoiceData.subtotal = invoice.invoice.Subtotal;
      this.invoiceData.tax_rate = invoice.invoice.TaxRate;
      this.invoiceData.tax_amount = invoice.invoice.TaxAmount;
      this.invoiceData.discount = invoice.invoice.Discount;
      this.invoiceData.other_cost = invoice.invoice.OtherCost;
      this.invoiceData.subtotal_charge = invoice.invoice.SubtotalCharge;
      this.invoiceData.total = invoice.invoice.Total;
      this.invoiceData.tax_amount = invoice.invoice.TaxAmount;
      this.invoiceData.tax_rate = invoice.invoice.TaxRate;
      this.invoiceData.status = invoice.invoice.Status;
      this.invoiceData.payment = invoice.payment;
      this.invoiceData.bank = invoice.bank;
      this.bank = invoice.bank[0];
      this.invoiceData.bank_account = invoice.bankAccount;
      this.payment_method = invoice.paymentMethod[2];
      this.invoice_payment_cod = invoice.invoicePaymentCOD;

      this.invoiceData.payment_method = invoice.paymentMethod;
      this.invoiceData.sales_order = [];
      for (var x in invoice.invoiceLine) {
        invoice.invoiceLine[x].total = invoice.invoiceLine[x].Line.reduce(
          (acc, value) => acc + value.Total,
          0
        );
        this.invoiceData.sales_order.push(invoice.invoiceLine[x]);
        for (var y in this.invoiceData.sales_order[x].Line) {
          this.invoiceData.sales_order[x].Line[y].Quantity =
            this.invoiceData.sales_order[x].Line[y].Qty;
          this.invoiceData.sales_order[x].Line[y].SellPrice =
            this.invoiceData.sales_order[x].Line[y].Price;
        }
      }
      this.invoiceData.paid = invoice.payment.reduce(
        (acc, value) => acc + value.PaymentAmount,
        0
      );
      this.invoiceData.unpaid =
        parseFloat(this.invoiceData.total) - parseFloat(this.invoiceData.paid);
      this.payment_amount = this.formatPrice(
        this.invoiceData.unpaid.toString()
      );
      const now = new Date();
      this.now = this.dateFormat(now.toString());
      return true;
    },
  },
};
</script>
<style scoped>
.margin-btm {
  margin-bottom: 1rem !important;
}
.vs-input--input.normal {
  text-align: end;
}
</style>
